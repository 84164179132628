import './informacion.css'
import timer from '../../../../Informacion/timer.png'
import support from '../../../../Informacion/support.png'
import qualifed from '../../../../Informacion/qualifed.png'
import chile2 from '../../../../Informacion/chile2.png'

export function Informacion(){

    const cards = [
        {
            image: chile2,
            title: 'Empresa Chilena',
            text: 'Conocemos las dificultades a las que se enfrentan nuestros clientes y por eso les brindamos soluciones reales y eficaces'
        },
        {
            image: timer,
            title: 'Desde 1992',
            text: 'Durante el transcurso de este tiempo hemos ganado la confianza de nuestros clientes debido al compromiso de colaboración'
        },
        {
            image: support,
            title: 'Soporte Integral',
            text: 'Nuestros clientes estarán protegidos y contarán con el respaldo de una empresa con experiencia en el área informática'
        },
        {
            image: qualifed,
            title: 'Personal Altamente Calificado',
            text: 'Contamos con los mejores técnicos e ingenieros para brindar un servicio de primera a todos nuestros clientes'
        }
    ]

    return <div className="containerInformacion">
        <div className='informacion'>
            {cards.map((item, index) => 
                <div className='card-informacion' key={index} data-aos={window.screen.width<=425? index==0?"":"fade-left":"fade-left"} data-aos-delay={cards.indexOf(item)*100}>
                    <img 
                    src={item.image} 
                    alt={item.title}
                    style={{
                        filter: 'drop-shadow(0 0 10px rgba(214, 135, 39, 0.8))'
                      }}
                    />
                    <p className='informacion-title'>{item.title}</p>
                    <p className='informacion-text'>{item.text}</p>
                </div>)
            }
        </div>
    </div>
}