import './gcomercial.css'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import ssDashboard from '../../../../POS/screenshot_dashboard.jpg'
import ssDashboardTwo from '../../../../GestionComercial/screenshot-dashboard-dimasoft.jpg'
import ssDashboardMovile from '../../../../POS/screenshot-dashboard-movile.png'
import post_dtes from '../../../../POS/post-dtes.jpg'
import post_compras from '../../../../POS/post-compras.jpg'
import ssReportePos from '../../../../POS/report-pos.png'
import dteCompras from '../../../../Others/Dte-de-compras-dimasoft-4.png'
import ventasImage from '../../../../GestionComercial/ventas-dimasoft.webp'
import productosImage from '../../../../GestionComercial/productos-inventario.webp'
import tesoreriaImage from '../../../../GestionComercial/tesoreria-dimasoft.webp'
import seguridadImage from '../../../../GestionComercial/seguridad-dimasoft.webp'
import comprasImage from '../../../../GestionComercial/compras-dimasoft.webp'
import caracteristicasImage from '../../../../GestionComercial/caracteristicas-erp-dimasoft.png'

import { Contactenos } from '../../Contactenos/Contactenos'
import { useState } from 'react'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

import { FaCheck,FaAngleRight } from "react-icons/fa";
import { LuFileBadge,LuUploadCloud } from "react-icons/lu";
import { Link as Scroll} from 'react-scroll'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export function GComercialR(){
    return <div className='containerGComercial'>
        <TitleDescription title='Software de Ventas.Software InventarioERP Gestión Comercial, Sistema. Software de Gestion Comercial - Dimasoft' description='Softwarte de ERP o sistema ERP de Gestion Comercial robusto, completo, facil de usar y en versión Cloud. ERP Chile para pymes y grandes empresas. Sofware facturacion. Software Boletas.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='gc-title-section'>
            <h1>ERP - Gestión Comercial v7.1</h1>
        </div>
        <GComercial/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function GComercial(){

    const caracteristicas = [
        'Manejo y administración de Sucursales sin limite ni costo adicional.',
        'Manejo de Productos o SKU sin limite.',
        'Generación de Factura Electronica ( Dtes ) ilimitadas, sin cobros por cantidad de emisión.',
        'Generación de Boletas Electronicas ilimitadas, sin cobros por cantidad de emisión.',
        'Cesión de Documentos Electrónicos ( Factoring )',
        'Integración con su e-Commerce o con nuestro e-Commerce.',
        'Integración - API'
    ]

    return <div className='gcomercial'>
        
        <div className='line gradient line-small'></div>
        <div className='gc-caracteristicas'>
            <div className='left-content'>
                <p>El ERP – Gestión Comercial v7.1 de Dimasoft es un software robusto, completo y fácil de usar, el cual le permite crecer al nivel de exigencia de su empresa.</p>
                <ul>{caracteristicas.map((item,index)=>(
                        <li key={index}><FaAngleRight className='checkIcon'/> {item}</li>
                        ))
                    }
                </ul>
            </div>
            <img src={caracteristicasImage} alt="Imagen gestion comercial" loading='lazy' />
        </div>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br />
        <CardsDescription />
        <br /><br />
        <div className='line gradient line-small' data-aos="fade-right"></div>

        <div className='gc-content'>
            <h2>Flujo de los DTE's de compras</h2>
            <img className='imageCompras' src={dteCompras} alt="Flujograma Dtes" loading='lazy' />
        </div>

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={post_dtes} alt="screenshot dashboard" className='img-in-web perspective-left'/>
            </div>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>GESTIONA TUS DTEs</h3>
                <p> Administra tus Documentos Tributarios Electrónicos de manera eficiente. 
                    Visualiza, organiza y controla todos tus documentos en un solo lugar, 
                    con acceso inmediato a la información que necesitas..</p>
            </div>
        </div><br /><br /><br /><br />

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>MANTEN AL DÍA TUS COMPRAS</h3>
                <p>OPTIMIZA TUS COMPRAS
                Gestiona eficientemente tu proceso de adquisiciones. Compara proveedores y realiza un seguimiento preciso de tus gastos. Automatiza aprobaciones, genera informes detallados y mantén un control total sobre tu inventario y costos.</p>
            </div>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={post_compras} alt="screenshot dashboard" className='img-in-web perspective-right'/>
            </div>
        </div><br /><br /><br /><br />
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssDashboardTwo} alt="screenshot dashboard" className='img-in-web perspective-left'/>
            </div>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>DESCUBRE TUS ESTADÍSTICAS</h3>
                <p>Clarifica y adapta tus datos con nuestro sistema. Examina tus métricas de manera eficiente y comparte tus hallazgos sin complicaciones. Crea gráficos personalizables y descárgalos en formatos populares como PNG, CSV y SVG.</p>
            </div>
        </div><br /><br /><br /><br />


        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br /><br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>VISUALIZA TUS METRICAS</h3>
                <p>Visualiza y personaliza tus métricas con nuestro software. Genera gráficos adaptables y descárgalos en formatos populares como PNG, CSV y SVG. Analiza tus datos de manera efectiva y comparte fácilmente tus resultados.</p>
            </div>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssDashboard} alt="screenshot dashboard" className='img-in-web perspective-right'/>
            </div>
        </div><br /><br /><br /><br />

        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-50' data-aos="zoom-in">
                <img src={ssDashboardMovile} alt="screenshot dashboard mobile" className='img-in-movile'/>
            </div>
            <div className='pos-advance-content-50' data-aos="fade-right">
                <h3>ALTA ACCESIBILIDAD</h3>
                <p>Accede a tus métricas desde cualquier lugar y en cualquier momento con nuestra plataforma, totalmente compatible con dispositivos móviles y de escritorio. Nuestro diseño responsive garantiza una experiencia uniforme en todos tus dispositivos.</p>
            </div>
        </div>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <br />
        <div className='pos-advance-content-flex'>
            <div className='pos-advance-content-40' data-aos="fade-left">
                <h3>REPORTES</h3>
                <p>Genera informes completos y personalizables para respaldar su análisis. Con unos pocos clics, podrás crear informes profesionales que comuniquen sus datos de manera clara y efectiva.</p>
            </div>
            <div className='pos-advance-content-60' data-aos="zoom-in">
                <img src={ssReportePos} alt="screenshot dashboard" className='img-in-desktop desktop-report perspective-right'/>
            </div>
        </div><br /><br />
        <br />
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gc-content'>
            <h2>Software Versión Licencia</h2>
        </div>
        <ContainerLicense />

        <div className='line gradient line-small' data-aos="fade-right"></div>

        <div className='gc-content'>
            <h2>Software Versión Cloud</h2>
        </div>
        <ContainerCloud />
        
        <br />
        <h2>Contáctenos</h2>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='gc-content' name='contactenos'>
            <Contactenos/>
        </div>
    </div>
}

function CardsDescription(){
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 2400 },
            items: 6
        },
        largeDesktop: {
            breakpoint: { max: 2400, min: 1600 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1600, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1200, min: 990 },
            items: 3
        },
        tablet_s: {
            breakpoint: { max: 990, min: 720 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 720, min: 530 },
            items: 1
        },
        mobile_s: {
            breakpoint: { max: 530, min: 0 },
            items: 1
        }
    }
    
    const productos = [
        'Manejo y Control de Stock.',
        'Multi Bodegas.',
        'Control de Costo de Productos y Utilidades.',
        'Definición de listas de precios configurables.',
        'Precios especiales asignados a cliente.',
        'Precios por tramos por cantidad vendida.',
        'Estadística de Productos vendidos. (el más vendido, el menos vendido).',
        'Historial de productos vendidos por Cliente.',
        'Completo control de costos de productos, variaciones de costos, cantidades compradas, a que proveedor.',
        'Informe de Utilidades por día, semana, mensual.',
        'Auditoria de movimientos de productos, entradas, salidas y saldos, el cual permite una disminución en el concepto de pérdidas de Stock.',
        'Informes de Stock valorizados.',
        'Informe de Reposición de Stock.',
        'Informe de Inventario a una fecha determinada.',
        'Carga masiva de Productos flexible y dinámica.',
        'Comprobante interno de Entradas y Salidas de Productos, para registro de mermas y/o consumo interno.'
    ]
    const ventas = [
        'Cotizaciones',
        'Notas de Pedido con Reserva de Stock.',
        'Factura Electrónica y Boleta Electrónica.',
        'Boleta Electrónica con registro de cliente o nominativa.',
        'Guía de Despacho Electrónica.',
        'Optimo manejo de Facturación de Guías de Despacho.',
        'Notas de Crédito Electrónica con captura de contenido desde la Factura Electrónica.',
        'Factura de Exportación Electrónica.',
        'Control de Límite Crédito para clientes.',
        'Comisión de Vendedores.'
    ]
    const tesoreria = [
        'Flexible Control de Pagos, Pagos de múltiples Facturas y abonos.',
        'Pago de facturas con varios cheques u otros.',
        'Manejo de Cheques en cartera.',
        'Informe de Facturas impagas agrupadas por cliente.',
        'Informe de Facturas vencidas agrupadas por cliente.',
        'Control de Gastos menores ( Caja Chica ).'
    ]
    const compras = [
        'Orden de Compra, con enlace de Factura o Guía Despacho de Compra.',
        'Manejo de productos pendientes por recibir.',
        'Manejo y administración de los DTEs de Compras.',
        'Sincronización de Facturas de Compras desde SII.',
        'Control de Pagos Documentos de Compra.',
        'Informe de Facturas impagas agrupadas por proveedor.',
        'Informe de Facturas vencidas agrupadas por proveedor.'
    ]
    const seguridad = [
        'Administración de Perfiles de Usuarios.',
        'Bitácora de transacciones por Usuario.',
        'Respaldo de Datos Automatizado.'
    ]
    
    return <div className='gc-cards-description' data-aos="fade-left">
        <Carousel responsive={responsive} containerClass="carousel-container">
            <CardGC image={productosImage} altImage='Imagen Ventas' title='PRODUCTOS / INVENTARIO' descriptions={productos} countShow={3}/>

            <CardGC image={ventasImage} altImage='Imagen Ventas' title='VENTAS' descriptions={ventas}/>
            
            <CardGC image={tesoreriaImage} altImage='Imagen Tesoreria' title='TESORERÍA Y COBRANZA' descriptions={tesoreria}/>

            <CardGC image={comprasImage} altImage='Imagen Compras' title='COMPRAS' descriptions={compras} countShow={2}/>
            
            <CardGC image={seguridadImage} altImage='Imagen Security' title='SEGURIDAD' descriptions={seguridad}/>
        </Carousel>
    </div>
}

function CardGC({image,altImage,title,descriptions,countShow=3}){
    const [showCard, setShowCard] = useState(false)

    const showerCard = ()=>{
        setShowCard(!showCard)
    }

    return <div className="gc-card">
        <img className='img-card' src={image} alt={altImage} loading='lazy' />
        <div className='content-card'>
            <h3><b>{title}</b></h3>
            <ul>
                {descriptions.map((item,index)=>(
                (index<countShow||showCard)?
                <li key={index}>{item}</li>:''
                ))}
            </ul>
            <span onClick={showerCard}>
                {descriptions.length>countShow?'Ver '+(showCard?'menos':'mas'):''}
            </span>
        </div>
    </div>
}

function ContainerLicense(){
    const cards = {
        licencia:{
            title:"Versión Licencia",
            price:"13 uf",
            description:[
                "3 Usuarios",
                "Pago Unico",
                "No Requiere internet continua.",
            ],
            icon:<LuFileBadge className='gc-license-icon'/>
        },
        capacitacion:{
            title:"Implementación y Capacitación",
            description:[
                "Desde 4 UF + iva.",
                "Instalación",
                "Configuración",
                "Capacitación ",
            ]
        },
        factura:{
            title:"Facturación Electrónica",
            description:[
                "15 UF + iva. Por empresa",
                "Set Básico DTEs",
                "Factura",
                "Guia de Despacho",
                "Nota de Debito y Crédito ilimitadas.",
                "Cesión de Documentos (Factoring)"
            ]
        },
        boleta:{
            title:"Boleta Electrónica",
            description:[
                "8 UF + iva.",
                "Por Empresa",
                "Boletas Ilimitadas",
            ]
        }
    }
    return <div className='gc-container-license'>
        <div className='gc-box-license'>
            <CardLicense principal={true} principalPrice={cards.licencia.price} principalIcon={cards.licencia.icon} title={cards.licencia.title} description={cards.licencia.description}/>
            <CardLicense title={cards.capacitacion.title} description={cards.capacitacion.description}/>
            <CardLicense title={cards.factura.title} description={cards.factura.description}/>
            <CardLicense title={cards.boleta.title} description={cards.boleta.description}/>
        </div>
        <br />
    </div>
}

function CardLicense({title,description=[],countShow=8,subtitle=false,principal=false, principalPrice='', principalIcon=false}){
    let [showCardLicense, setShowCardLicense] = useState(false)

    return <div className={"gc-content-card-license" + (principal?' principal-card':'')}>
        <div className='gc-title-card-license'>
            <h3><b>{title}</b></h3>
        </div>
        {subtitle?<div className='gc-subtitle-card-license'>{subtitle}</div>:''}
        <div className='gc-body-card-license'>
            <ul>
                {principal&&principalPrice?<li key={6}><FaCheck className='check-body-card-license'/> Desde <strong>{principalPrice}</strong> + iva</li>:''}
                {description.map((item,index)=>(
                    (index<countShow||showCardLicense)?
                    <li key={index}><FaCheck className='check-body-card-license'/> {item}</li>:''
                ))}
            </ul>
            {principalIcon?principalIcon:''}
        </div>
        <div className='gc-footer-card-license'>
            <Scroll activeClass="active" to="contactenos" smooth={true} offset={-60} duration={500}>
                <button>Mas información</button>
            </Scroll>
        </div>
    </div>
}

function ContainerCloud(){
    const cards = {
        cloud:{
            title:"Versión Cloud",
            price:"1,5 uf",
            description:[
                "Por Usuario",
                "Activación 10 UF + Iva. Pago Único",
                "Facturación Mensual",
            ],
            icon:<LuUploadCloud className='gc-cloud-icon'/>
        },
        factura:{
            title:"Facturación Electrónica (por empresa)",
            subtitle: "Incluye",
            description:[
                "Boleta Electrónica",
                "Factura Electrónica",
                "Guia de Despacho",
                "Nota Crédito / Nota Debito",
                "Cesión de Documentos (Factoring)",
                "Factura de Compra Electrónica de Terceros"
            ]
        },
        capacitacion:{
            title:"Implementación y Capacitación",
            subtitle:"Incluye",
            description:[
                "Configuración",
                "Capacitación ",
            ]
        },
        integracion:{
            title:"Integración E-Commerce",
            description:[
                "Desde 2 UF + iva (mensual)",
                "E-commerce",
                "API",
            ]
        }
    }
    return <div className='gc-container-license'>
        <div className='gc-box-license'>
            <CardLicense principal={true} principalPrice={cards.cloud.price} principalIcon={cards.cloud.icon} title={cards.cloud.title} description={cards.cloud.description}/>
            <CardLicense title={cards.factura.title} subtitle={cards.factura.subtitle} description={cards.factura.description}/>
            <CardLicense title={cards.capacitacion.title} subtitle={cards.capacitacion.subtitle} description={cards.capacitacion.description}/>
            <CardLicense title={cards.integracion.title} description={cards.integracion.description}/>
        </div>
        <br />
    </div>
}