import './kitPuntoVenta.css'
import { Footer} from '../Footer/Footer'
import puntoVenta from '../../../Products/kit-punto-venta-dimasoft.webp'
import pcTodoEnUno from '../../../Products/pc-todo-en-uno-dimasoft.webp'
import gavetaDinero from '../../../Products/gaveta-de-dinero-dimasoft.webp'
import scanner from '../../../Products/scanner-dimasoft.webp'
import cajaRollos from '../../../Products/caja-rollos-termicos-dimasoft.webp'
import impresora from '../../../Products/impresora-termica-dimasoft-fondo.webp'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';
import { MdStorefront } from "react-icons/md";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FirstHeader, SecondHeader} from '../Header/Headers'


export function KitPuntoVentaR(){
    return <div className='containerProduct'>
        <TitleDescription title='Kit Punto de Venta POS Chile - Dimasoft' description='Kit Punto de Venta Chile. Kit POS para punto de venta, Minimarket, Negocio, Ferreteria, Restaurante, Papeleria, Softwares Chile'/>
        <FirstHeader/>
        <SecondHeader/>
        <Product name="Kit Punto de Venta POS ONE T3-7000" 
            subtitle="Kit de punto de venta c/ gaveta + impresora + scanner " 
            description={[
                '1 POS ALL IN ONE T3-7000 NO TOUCH.',
                '1 LICENCIA WINDOWS 10 PRO.',
                '1 IMPRESORA TERMICA.',
                '1 SCANNER DE PISTOLA',
                '1 GAVETA DE DINERO,',
                '1 MOUSE.',
                '1 TECLADO.',
            ]}
            url="https://tienda.dimasoft.cl/products/KIT-POS-ONE-T37000"
        />
        <hr/>
        <RelatedProducts/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}

function Product({name,subtitle,description,url}){

    return <div className='product'>
        <div className='image-product'>
            <img src={puntoVenta} alt="Kit-Punto-Venta" loading='lazy' />
        </div>
        <div className='details-product'>
            <h2><b>{name}</b></h2>
            <p className='subtitle-product'>{subtitle}</p>
            <div className='description-product'>
                <p><b>Descripción:</b></p>
                <ul>{description.map((item,index)=>(
                        <li key={index}>{item}</li>
                        ))
                    }
                </ul>
            </div>
            <a href={url} className='store-button'>Ir a tienda &nbsp;<MdStorefront className='store-icon'/></a>
        </div>
    </div>
}

function RelatedProducts(){
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 2400 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 2400, min: 1200 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1200, min: 990 },
            items: 4
        },
        tablet_s: {
            breakpoint: { max: 990, min: 720 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 720, min: 530 },
            items: 2
        },
        mobile_s: {
            breakpoint: { max: 530, min: 0 },
            items: 1
        }
      };

    return <div className='related-product'>
        <h2>Productos relacionados</h2>
        <Carousel responsive={responsive} containerClass="carousel-container">
            <CardRelatedProduct name="IMPRESORA TERMICA ONE 580 NX-II USB / RED" image={impresora} url='https://tienda.dimasoft.cl/products/IMPRESORA-TERMICA-ONE-580-NXII-USBRED'/>
            <CardRelatedProduct name="SCANNER ONE 8-RAY" image={scanner} url='https://tienda.dimasoft.cl/products/SCANNER-ONE-8RAY'/>
            <CardRelatedProduct name="GAVETA DE DINERO ON CD806" image={gavetaDinero} url='https://tienda.dimasoft.cl/products/GAVETA-DE-DINERO-ON-CD806'/>
            <CardRelatedProduct name="POS ONE T3-7000 4 RAM 120 SSD WIFI" image={pcTodoEnUno} url='https://tienda.dimasoft.cl/products/POS-ONE-T37000-4-RAM-120-SSD-WIFI'/>
            <CardRelatedProduct name="CAJA - 120 ROLLOS TERMICOS 57X17MT X 13 48GR" image={cajaRollos} url='https://tienda.dimasoft.cl/products/CAJA120-ROLLOS-TERMICOS-57x17MT-X-13-48GR'/>
        </Carousel>
    </div>
}

function CardRelatedProduct({name,image,url}){

    const splitName = (name)=>{
        if(name.length>20){
            return name.slice(0,19)+'...'
        }else{
            return name
        }
    }

    return <div className='card-product'>
        <img src={image} alt="producto-dimasoft" />
        <p>{splitName(name)}</p>
        <a href={url} className='button-card-product'>Ver producto</a>
    </div>
}
